import {
    Box,
    Flex,
    VStack,
    useBreakpointValue,
    Heading,
    Text,
    Image,
    Stack,
    Container,
    useColorModeValue,
    SimpleGrid,
} from '@chakra-ui/react';
import WorkImage from "../assets/images/work.jpg";

export default function Work() {
    return (
        <>
            <Flex
                w={'full'}
                h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                backgroundImage={
                    'url(https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80)'
                }
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                <VStack
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                    <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm: 'sm' })}>
                            WE ARE HEROS OF
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.1}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                            Design, Development, and Identify your product.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm: 'xs' })}>
                            Leading IT company with soild design and Development expertise.
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW={'7xl'} py={12}>
                <Flex mt={10} justifyContent={"center"} mb={10}>
                    <Stack>
                        <Heading>Our Work</Heading>
                        <hr />
                    </Stack>
                </Flex>
                {/* <Stack py={6} display={"flex"} spacing={5} justifyContent={"center"} alignItems={"center"} direction={useBreakpointValue({ sm: "column", lg: "row" })}> */}
                <SimpleGrid columns={{ base: 1, md: 3 }} pt={4} spacing={10}>
                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            // h={'px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={WorkImage}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                Hospital / Covid / AgeCare CMS
                            </Heading>
                            <Text color={'gray.500'}>
                                It is a web-based system which is mainly used to manage hospital, doctors, and patients. Patient is a new user and when the new patient has register, doctors has been notifying about the patient. Later, all the treatments and the test results of the patient has been displayed in the system.
                            </Text>
                        </Stack>
                    </Box>

                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            // h={'px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={WorkImage}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                Classic GT
                            </Heading>
                            <Text color={'gray.500'}>
                                ClassicGT is developed by car enthusiasts, for car enthusiasts and investors alike. We bring modern big data capabilities to the traditional world of classic & sports cars, providing never before seen insight (like a stock market trading app) to provide accurate and genuine car valuations and tell-tale price trends to make buying, owning and selling classic & sports cars fun!
                            </Text>
                        </Stack>
                    </Box>

                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            // h={'px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={WorkImage}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                Radio Web
                            </Heading>
                            <Text color={'gray.500'}>
                                It is a subscription based web, Android and IOS music application where user can listen music audios, and create and share playlist with others. We used zoho subscription for web and google pay or apple pay application subscription management. Elastic search was used to get faster response to fetch required records from thousands of records.
                            </Text>
                        </Stack>
                    </Box>


                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            // h={'px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={WorkImage}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                Sleepwell
                            </Heading>
                            <Text color={'gray.500'}>
                                Sleepwell is a market leader offering a pan-India distribution and manufacturing network, a diverse product portfolio, and robust R&D capabilities.
                            </Text>
                        </Stack>
                    </Box>

                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            // h={'px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={WorkImage}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                MindNavigator
                            </Heading>
                            <Text color={'gray.500'}>
                                The human centric solution to employee engagement and excellence at scale is to evolve how individuals make meaning of themselves, their relationships and their work, Mind-First.
                            </Text>
                        </Stack>
                    </Box>
                </SimpleGrid>
                {/* </Stack> */}
            </Container>
        </>
    );
}