import {
    VStack,
    useBreakpointValue,
    Flex,
    Text,
    Stack,
} from '@chakra-ui/react';
import {
    Email,
    Location,
    Phone,
    Clock
} from "../Config/icon";

export default function Contact() {
    return (
        <>
            <Stack>
                <Flex
                    w={'full'}
                    h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                    backgroundImage={
                        'url(https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
                    }
                    backgroundSize={'cover'}
                    backgroundPosition={'center center'}>
                    <VStack
                        w={'full'}
                        justify={'center'}
                        px={useBreakpointValue({ base: 4, md: 8 })}
                        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
                            <Text
                                color={'white'}
                                fontWeight={700}
                                lineHeight={1.2}
                                borderBottomWidth={0.5}
                                borderWidth={1}
                                p={2}
                                fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
                                Contacting VIN Info Tech
                            </Text>
                        </Stack>
                    </VStack>
                </Flex>
            </Stack>
            <Stack minH={'65vh'} direction={{ base: 'column', md: 'row' }} mx={10}>
                <Flex px={8} py={8} flex={1} align={'center'} justify={'flex-start'}>
                    <Stack spacing={6} w={'full'} maxW={'md'} mx={10}>
                        <Text
                            fontSize={useBreakpointValue({ base: '1xl', md: '2xl', lg: '2xl', sm: '2xl' })}
                            fontWeight={700}>
                            Let's Start a Conversation
                        </Text>
                        <Flex>
                            <Email size={'28px'} />
                            <Text px={'2'}>contact@vininfotech.com</Text>
                        </Flex>
                        <Flex>
                            <Location size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>Rajkot, Gujarat</Text>
                        </Flex>
                        <Flex>
                            <Phone size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>+91 9067807321</Text>
                        </Flex>
                        <Flex>
                            <Clock size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>Monday - Saturday 9AM to 5PM (Sunday Close)</Text>
                        </Flex>
                    </Stack>
                </Flex>
            </Stack>
        </>
    );
}