import {
    Box,
    Stack,
    Heading,
    Text,
    Button,
    useColorModeValue,
    HStack,
    VStack,
    ListItem,
    ListIcon,
    List,
} from '@chakra-ui/react';
import { CheckCircle } from "../Config/icon";

function PriceWrapper({ children }) {
    return (
        <Box
            mb={4}
            maxW={'320px'}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

export default function Career() {
    return (
        <>
            <Box py={12}>
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl">
                        Create Smart things with us!
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                        We are always egaer to meet fresh talent, so check out our open positions.
                    </Text>
                </VStack>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}>
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Business Development Executive
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="md" fontWeight="600">
                                    Experience -
                                </Text>
                                <Text fontSize="md" fontWeight="900">
                                    2
                                </Text>
                                <Text fontSize="md" color="gray.500">
                                    Years
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 1
                                </ListItem>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 2
                                </ListItem>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 3
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button w="full" colorScheme="teal">
                                    Apply Now
                                </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>

                    <PriceWrapper>
                        <Box position="relative">
                            <Box py={4} px={12}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Front-End Developer
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="md" fontWeight="600">
                                        Experience -
                                    </Text>
                                    <Text fontSize="md" fontWeight="900">
                                        2
                                    </Text>
                                    <Text fontSize="md" color="gray.500">
                                        Years
                                    </Text>
                                </HStack>
                            </Box>
                            <VStack
                                bg={useColorModeValue('gray.50', 'gray.700')}
                                py={4}
                                borderBottomRadius={'xl'}>
                                <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 1
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 2
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 3
                                    </ListItem>
                                </List>
                                <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="teal">
                                        Apply Now
                                    </Button>
                                </Box>
                            </VStack>
                        </Box>
                    </PriceWrapper>
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                No Current Openings
                            </Text>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    Currently we have no openings for the job positions.
                                </ListItem>
                                <ListItem>Yet, keep looking for the opening; Else you can submit your CV/Resume.</ListItem>
                            </List>
                        </VStack>
                    </PriceWrapper>
                </Stack>
            </Box>
        </>
    );
}
