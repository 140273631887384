import {
    VStack,
} from '@chakra-ui/react';
import HomeCover from './Subcomponents/HomeCover';
import HomeServices from './Subcomponents/HomeServices';
import HomePortfolio from './Subcomponents/HomePortfolio';

export default function Home() {
    return (
        <VStack>
            <HomeCover />
            <HomeServices />
            <HomePortfolio />
        </VStack>
    );
}