import {
    Box,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    Flex,
    VStack,
    useBreakpointValue,
    useColorModeValue,
    Heading
} from '@chakra-ui/react';
import {
    Website,
    Api,
    Design,
} from '../Config/icon';

const Feature = ({ title, text, icon, iconBg }) => {
    return (
        <Stack alignItems={'center'}>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={iconBg}
                mb={1}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text color={'gray.600'} textAlign={"justify"}>{text}</Text>
        </Stack>
    );
};

export default function Services() {
    return (
        <>
            <Flex
                w={'full'}
                h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                backgroundImage={
                    'url(https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80)'
                }
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                <VStack
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                    <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm: 'sm' })}>
                            WE ARE HEROS OF
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.1}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                            Design, Development, and Identify your product.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm: 'xs' })}>
                            Leading IT company with soild design and Development expertise.
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
            <Box pb={20} maxW={'7xl'} py={12} mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
                <Flex justifyContent={"center"} mb={10}>
                    <Stack spacing={4}>
                        <Heading>Our Service Stack</Heading>
                        <hr />
                    </Stack>
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 3 }} pt={4} spacing={10}>
                    <Feature
                        icon={<Icon as={Website} color={'purple.500'} size="20px" />}
                        iconBg={useColorModeValue('purple.500', 'purple.900')}
                        title={'Web Development'}
                        text={'We make use of suitable technology stack for your business. We develop mobile responsive website sites correctly customized to your business need. We offer all round types of assistance in designing, developing and maintaining powerful web presence for your business.'}
                    />
                    <Feature
                        icon={<Icon as={Design} color={'yellow.500'} size="20px" />}
                        iconBg={useColorModeValue('blue.500', 'blue.900')}
                        title={'Product Development'}
                        text={'We are responsible for developing new products or improving existing products so that your business can meet customers requirements more effectively. we collaborating with marketing, technical and manufacturing specialists throughout the product-development process.'}
                    />
                    <Feature
                        icon={<Icon as={Api} color={'green.500'} size="20px" />}
                        iconBg={useColorModeValue('red.500', 'red.900')}
                        title={'Mobile App Development'}
                        text={'Application development involves the process, life, and creation of the software aimed at helping users. Mobile Developers work closely with computer analysts and engineers in using languages such as Java and C++ to develop the necessary specifications for software.'}
                    />
                </SimpleGrid>
                <Box bg={useColorModeValue('gray.100', 'gray.700')} boxShadow={'md'} h={'150px'} display={"flex"} alignItems={'center'} flexDir={'column'} mt={6} borderRadius={10}>
                    <Flex justifyContent={'center'} alignItems={'center'} py={5}>
                        <Heading>Hire Us</Heading>
                    </Flex>
                    <Flex justifyContent={'center'} alignItems={'center'}>
                        <Text fontSize={'xl'}>Help us build the next generation technology platfrom for your business.</Text>
                    </Flex>
                </Box>
            </Box>
        </>
    );
}