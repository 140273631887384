import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    Input,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import { Facebook, Instagram, LinkedinIn, MailSend } from "../Config/icon";

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function Footer() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box>
                            <Text fontSize="2xl">
                                VIN Info Tech
                            </Text>
                        </Box>
                        <Text fontSize={'sm'}>
                            VIN Info Tech © 2021. All rights reserved
                        </Text>
                        <Stack direction={'row'} spacing={6}>
                            <Link paddingRight={2} href={'#'}><Facebook size={'24px'} /></Link>
                            <Link paddingRight={2} href={'#'}><Instagram size={'24px'} /></Link>
                            <Link paddingRight={2} href={'#'}><LinkedinIn size={'24px'} /></Link>
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        <Link href={'/about'}>About Us</Link>
                        <Link href={'/services'}>Services</Link>
                        <Link href={'/work'}>Our Work</Link>
                        <Link href={'/career'}>Career</Link>
                        <Link href={'/contact'}>Contact Us</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Legal</ListHeader>
                        <Link href={'#'}>Privacy Policy</Link>
                        <Link href={'#'}>Terms of Service</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Have any Queries?</ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={'Your email address'}
                                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                border={0}
                                _focus={{
                                    bg: 'whiteAlpha.300',
                                }}
                            />
                            <IconButton
                                bg={useColorModeValue('green.400', 'green.800')}
                                color={useColorModeValue('white', 'gray.800')}
                                _hover={{
                                    bg: 'green.600',
                                }}
                                aria-label="Subscribe"
                                icon={<MailSend size={'24px'} />}
                            />
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}