import {
    Stack,
    Flex,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';

export default function HomeCover() {
    return (
        <Flex
            w={'full'}
            h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh'})}
            backgroundImage={
                'url(https://images.unsplash.com/photo-1600267175161-cfaa711b4a81?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
            }
            backgroundSize={'cover'}
            backgroundPosition={'center center'}>
            <VStack
                w={'full'}
                justify={'center'}
                px={useBreakpointValue({ base: 4, md: 8 })}
                bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                    <Text
                        color={'white'}
                        fontWeight={700}
                        textAlign={'center'}
                        fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm:'sm' })}>
                        WE ARE HEROS OF
                    </Text>
                    <Text
                        color={'white'}
                        fontWeight={700}
                        lineHeight={1.1}
                        textAlign={'center'}
                        fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                        Design, Development, and Identify your product.
                    </Text>
                    <Text
                        color={'white'}
                        fontWeight={700}
                        lineHeight={1.2}
                        textAlign={'center'}
                        fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm:'xs' })}>
                        Leading IT company with soild design and Development expertise.
                    </Text>
                </Stack>
            </VStack>
        </Flex>

    );
}